import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import PrimaryContent from '../components/primary-content'

class StandardPageTemplate extends React.Component {
    render() {
        const page = get(this.props, 'data.contentfulStandardPage')
        const hasLeadImage = typeof page.leadImage !== 'undefined' && page.leadImage !== null

        return (
            <Layout location={this.props.location}>
                <main>
                    <PrimaryContent data={page.primaryContent} />
                </main>
            </Layout>
        )
    }
}

export default StandardPageTemplate

export const pageQuery = graphql`
    query StandardPageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulStandardPage(slug: { eq: $slug }) {
            title
            slug
        }
    }
`
